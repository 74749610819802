/* Mimicking Tailwind's rounded-full */
.cvc-rounded-full {
    border-radius: 9999px;
}

/* Mimicking Tailwind's p-4 */
.cvc-p-4 {
    padding: 1rem;
    /* Tailwind's default spacing scale; 1rem = 16px */
}

.cvc-p-2 {
    padding: 0.5rem !important;
    /* Tailwind's default spacing scale; 1rem = 16px */
}

.cvc-p-3 {
    padding: 0.75rem !important;
}

/* Green for cleared */
.cvc-bg-lime-600 {
    background-color: #84cc16;
    /* Tailwind's lime-600 approximation */
}

/* Green for cleared */
.cvc-text-lime-600 {
    color: #84cc16;
    /* Tailwind's lime-600 approximation */
}

/* Red for rejected */
.cvc-bg-red-600 {
    background-color: #dc2626;
    /* Tailwind's red-600 approximation */
}

/* Orange for processing */
.cvc-bg-orange-500 {
    background-color: #f97316;
    /* Tailwind's orange-500 approximation */
}

/* Grey for default */
.cvc-bg-gray-400 {
    background-color: #a3a3a3;
    /* Tailwind's gray-400 approximation */
}


/* Mimicking Tailwind's text-white */
.csv-text-white {
    color: #ffffff;
}

/* Background color classes based on status */
.cvc-bg-green-100 {
    background-color: #c2e1d3;
}

.cvc-bg-red-100 {
    background-color: #f4dadc;
}

.cvc-bg-orange-100 {
    background-color: #ebd8c6;
}

.cvc-bg-gray-100 {
    background-color: #d0d2d7;
}

/* Mimicking Tailwind's h-[20px] */
.cvc-h-20 {
    height: 20px;
}

.cvc-h-30 {
    height: 30px;
}


/* Mimicking Tailwind's space-y-4 */
.cvc-space-y-4>*+* {
    margin-top: 1rem;
    /* Assuming 1rem = 16px, which matches Tailwind's default spacing scale */
}

/* Mimicking Tailwind's text-xl */
.cvc-text-xl {
    font-size: 1.25rem;
    /* 20px if base font size is 16px */
}

/* Mimicking Tailwind's font-medium */
.cvc-font-medium {
    font-weight: 500;
}

/* Mimicking Tailwind's text-red-700 */
.cvc-text-red-700 {
    color: #e3342f;
}

/* Mimicking Tailwind's bg-orange-500 */
.cvc-bg-orange-500 {
    background-color: #f6993f;
}

/* Mimicking Tailwind's hover:bg-orange-600 */
.cvc-hover\:bg-orange-600:hover {
    background-color: #de751f;
    /* Darker orange for hover state, adjust as needed */
}

/* Mimicking Tailwind's text-white */
.cvc-text-white {
    color: #ffffff;
}

/* Mimicking Tailwind's font-bold */
.cvc-font-bold {
    font-weight: 700;
}

/* Mimicking Tailwind's py-2 (padding top and bottom) */
.cvc-py-2 {
    padding-top: 0.5rem;
    /* 8px */
    padding-bottom: 0.5rem;
    /* 8px */
}

/* Mimicking Tailwind's px-4 (padding left and right) */
.cvc-px-4 {
    padding-left: 1rem;
    /* 16px */
    padding-right: 1rem;
    /* 16px */
}

/* Mimicking Tailwind's px-4 (padding left and right) */
.cvc-px-1 {
    padding-left: 0.25rem;
    /* 16px */
    padding-right: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    /* 16px */
}

/* Mimicking Tailwind's rounded */
.cvc-rounded {
    border-radius: 0.25rem;
    /* 4px */
}

.cvc-border-none {
    border: none;
    outline: none;
}

/* Mimicking Tailwind's mt-5 (margin-top) */
.cvc-mt-5 {
    margin-top: 1.25rem;
    /* 20px */
}

/* Mimicking Tailwind's bg-blue-500 */
.cvc-bg-blue-500 {
    background-color: #3490dc !important;
}

/* Mimicking Tailwind's hover:bg-blue-600 */
.cvc-hover\:bg-blue-600:hover {
    background-color: #2779bd;
    /* A darker blue for hover state, adjust as needed */
}

/* Mimicking Tailwind's text-white */
.cvc-text-white {
    color: #ffffff;
}

/* Mimicking Tailwind's font-bold */
.cvc-font-bold {
    font-weight: 700;
}

/* Mimicking Tailwind's py-2 (padding top and bottom) */
.cvc-py-2 {
    padding-top: 0.5rem;
    /* 8px */
    padding-bottom: 0.5rem;
    /* 8px */
}

/* Mimicking Tailwind's px-4 (padding left and right) */
.cvc-px-4 {
    padding-left: 1rem;
    /* 16px */
    padding-right: 1rem;
    /* 16px */
}

/* Mimicking Tailwind's rounded */
.cvc-rounded {
    border-radius: 0.25rem;
    /* 4px */
}

/* Mimicking Tailwind's text color classes */
.cvc-text-green-600 {
    color: #16a34a;
}

.cvc-text-red-600 {
    color: #dc2626;
}

.cvc-text-orange-600 {
    color: #ea580c;
}

.cvc-text-blue-600 {
    color: #2563eb;
}

.cvc-bg-blue-600 {
    background: #2563eb;
}

/* Mimicking Tailwind's background color classes */
.cvc-bg-green-100 {
    background-color: #d1fae5;
}

.cvc-bg-red-100 {
    background-color: #fee2e2;
}

.cvc-bg-orange-100 {
    background-color: #ffedd5;
}

.cvc-bg-gray-100 {
    background-color: #f3f4f6;
}

/* Mimicking Tailwind's margin and padding classes */
.cvc-mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.cvc-mt-5 {
    margin-top: 1.25rem;
    /* 20px */
}

.cvc-p-4 {
    padding: 1rem;
    /* 16px */
}

.cvc-rounded-lg {
    border-radius: 0.5rem;
    /* 8px */
}

/* Mimicking Tailwind's border classes */
.cvc-border {
    border: 1px solid;
}

.cvc-border-gray-100 {
    border-color: #f3f4f6;
}

/* Mimicking Tailwind's fixed, inset, bg-opacity, and transition-opacity classes */
.cvc-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cvc-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.cvc-bg-gray-500 {
    background-color: #6b7280;
}

.cvc-bg-opacity-75 {
    background-color: rgba(107, 114, 128, 0.75);
}

.cvc-transition-opacity {
    transition: opacity 0.3s ease-in-out;
}

/* Mimicking Tailwind's flex, min-h-full, items-center, justify-center classes */
.cvc-flex {
    display: flex;
}

.cvc-min-h-full {
    min-height: 100%;
}

.cvc-items-center {
    align-items: center;
}

.cvc-justify-center {
    justify-content: center;
}

/* Additional classes for modal and button styling */
.cvc-bg-white {
    background-color: #ffffff;
}

.cvc-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}


.cvc-px-5 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.cvc-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.cvc-text-black {
    color: #000000;
}

.cvc-text-gray-500 {
    color: #6b7280;
}

.cvc-text-sm {
    font-size: 0.875rem;
    /* 14px */
}

.cvc-text-lg {
    font-size: 1.125rem;
    /* 18px */
}

.cvc-text-2xl {
    font-size: 1.5rem;
}

.cvc-font-medium {
    font-weight: 500;
}

.cvc-rounded {
    border-radius: 0.25rem;
}

.cvc-underline {
    text-decoration: underline;
}

.cvc-no-underline {
    text-decoration: none;
}

.cvc-relative {
    position: relative;
}

.cvc-inline-block {
    display: inline-block;
}

.cvc-text-left {
    text-align: left;
}

.cvc-border-none {
    border: none;
}

.cvc-rounded-full {
    border-radius: 9999px;
}

.cvc-mr-3 {
    margin-right: 0.75rem;
}

.cvc-mr-2 {
    margin-right: 0.5rem;
    /* 8px */
}

.cvc-text-white {
    color: #ffffff !important;
}

.cvc-mb-2 {
    margin-bottom: 0.5rem;
    /* 8px */
}


.cvc-mt-2 {
    margin-top: 0.5rem;
    /* 8px */
}

.cvc-absolute {
    position: absolute;
}

.cvc-right-0 {
    right: 0;
}

.cvc-w-48 {
    width: 12rem;
}

.cvc-w-72 {
    width: 18rem;
}

.cvc-mt-2 {
    margin-top: 0.5rem;
}

.cvc-mt-3 {
    margin-top: 0.75rem;
}

.cvc-bg-white {
    background-color: #ffffff;
}

.cvc-divide-y {
    border-bottom: 1px solid #e5e7eb;
}

/* Approximation */
.cvc-divide-gray-100 {
    border-color: #f3f4f6;
}

.cvc-rounded-md {
    border-radius: 0.375rem;
}

.cvc-shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cvc-shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.cvc-shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.cvc-ring-1 {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.cvc-ring-black {
    --tw-ring-color: rgba(0, 0, 0, 0.5);
}

/* Tailwind uses custom properties for rings */
.cvc-ring-opacity-5 {
    --tw-ring-opacity: 0.05;
}

/* This is an approximation */
.cvc-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.cvc-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.cvc-group {
    /* Group hover not directly replicable in CSS */
}

.cvc-flex {
    display: flex;
}

.cvc-items-center {
    align-items: center;
}

.cvc-w-full {
    width: 100%;
}

.cvc-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.cvc-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.cvc-text-sm {
    font-size: 0.875rem;
}

.cvc-text-xs {
    font-size: 0.75rem;
    /* 12px */
}

.cvc-bg-gray-100 {
    background-color: #f3f4f6;
}

.cvc-text-gray-900 {
    color: #1f2937;
}

.cvc-text-gray-700 {
    color: #4b5563;
}

.cvc-text-gray-400 {
    color: #9ca0a5;
}

/* Button Base Styles */
.cvc-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.cvc-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.cvc-text-sm {
    font-size: 0.875rem;
    /* 14px */
}

.cvc-font-semibold {
    font-weight: 600;
}

.cvc-text-gray-700 {
    color: #4a5568;
}

.cvc-bg-blue-200 {
    background-color: #bfdbfe;
}

.cvc-rounded-full {
    border-radius: 9999px;
}

.cvc-hover\:bg-gray-300:hover {
    background-color: #e2e8f0;
}

.cvc-focus\:outline-none:focus {
    outline: none;
}

.cvc-focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    /* This simulates the Tailwind focus ring */
}

/* Dropdown Menu Styles */
.cvc-relative {
    position: relative;
}

.cvc-inline-block {
    display: inline-block;
}

.cvc-text-left {
    text-align: left;
}

.cvc-absolute {
    position: absolute;
}

.cvc-right-0 {
    right: 0;
}

.cvc-z-10 {
    z-index: 10;
}

.cvc-w-48 {
    width: 12rem;
    /* 192px */
}

.cvc-mt-2 {
    margin-top: 0.5rem;
}

.cvc-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.cvc-bg-white {
    background-color: #ffffff;
}

.cvc-border {
    border: 1px solid #e2e8f0;
    /* Light gray border */
}

.cvc-rounded {
    border-radius: 0.25rem;
}

.cvc-shadow-xl {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Link Styles */
.cvc-block {
    display: block;
}

.cvc-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.cvc-hover\:bg-blue-500:hover {
    background-color: #4299e1;
}

.cvc-hover\:text-white:hover {
    color: #ffffff;
}

.cvc-cursor-pointer {
    cursor: pointer;
}

/* flex */
.cvc-flex {
    display: flex;
}

/* justify-between */
.cvc-justify-between {
    justify-content: space-between;
}

/* items-center */
.cvc-items-center {
    align-items: center;
}

/* text-2xl */
.cvc-text-2xl {
    font-size: 1.5rem;
    /* 24px */
}

/* font-medium */
.cvc-font-medium {
    font-weight: 500;
}

/* leading-6 */
.cvc-leading-6 {
    line-height: 1.5;
}

/* text-gray-900 */
.cvc-text-gray-900 {
    color: #111827;
}

/* text-gray-500 */
.cvc-text-gray-500 {
    color: #6B7280;
}

/* hover:text-gray-700 */
.cvc-hover\:text-gray-700:hover {
    color: #374151;
}

/* focus:outline-none */
cvc- .focus\:outline-none:focus {
    outline: 0;
}

/* focus:ring-2 */
.cvc-focus\:ring-2:focus {
    box-shadow: 0 0 0 2px rgba(156, 163, 175, 0.5);
}

/* focus:ring-gray-500 */
.cvc-focus\:ring-gray-500:focus {
    box-shadow: 0 0 0 2px #6B7280;
}